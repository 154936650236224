import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import ServiceDetails from "@/components/service-details";
import CallToActionOne from "@/components/call-to-action-one";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
import SEO from "@/components/seo";

const WebsiteGraphics = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <SEO
          title="Solxk - Dizajn grafik"
          description="Zbuloni shërbimet e jashtëzakonshme grafike të uebsajtit të Solxk Përmirësoni praninë tuaj dixhitale me zgjidhjet tona të projektimit të përshtatura për zhvilluesit dhe bizneset."
        />
        <Layout PageTitle="Dizajn grafik">
          <HeaderOne />
          <PageBanner title="Dizajn grafik" name="Service" />
          <ServiceDetails />
          <CallToActionOne extraClassName="ready" />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default WebsiteGraphics;
